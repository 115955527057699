.capabilityItem {
	border-top: black solid 2px;

	.title {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 20px 0;

		span {
			font-size: 35px;
		}

		.closed {
			.vertical {
				transition: all 0.5s ease-in-out;
				transform: rotate(-90deg);
			}

			.horizontal {
				transition: all 0.5s ease-in-out;
				transform: rotate(-90deg);
				opacity: 1;
			}
		}

		.opened {
			opacity: 1;

			.vertical {
				transition: all 0.5s ease-in-out;
				transform: rotate(90deg);
			}

			.horizontal {
				transition: all 0.5s ease-in-out;
				transform: rotate(90deg);
				opacity: 0;
			}
		}

		.circle-plus {
			font-size: 16px;
			width: 64px;
			height: 64px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.circle-plus .circle {
			position: relative;
			width: 41px;
			height: 41px;
		}

		.circle-plus .circle .horizontal {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 30px;
			height: 5px;
			margin-top: -2.5px;
			margin-left: -15px;
			background-color: black;
		}

		.circle-plus .circle .vertical {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 5px;
			height: 30px;
			margin-top: -15px;
			margin-left: -2.5px;
			background-color: black;
		}

		.button {
			height: 50px;
			cursor: pointer;
		}
	}

	.desc {
		font-weight: lighter;
		overflow: hidden;
		line-height: 35px;
		width: 50%;
		margin-bottom: 20px;
	}

	@media screen and (max-width: 549px) {
		.title span {
			font-size: 30px;
		}
		.desc {
			width: 100%;
		}
	}

	@media screen and (max-width: 516px) {
		.title span {
			font-size: 20px;
		}
		.desc {
			width: 100%;
		}
	}
}
