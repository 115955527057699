.about {
	display: grid;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 100px 0;
	color: white;
	background: url('../../assets/bg.png') no-repeat center center;
	background-size: cover;

	.content {
		display: flex;
		justify-content: space-between;
		width: 90vw;

		.stats {
			font-weight: bold;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 50%;

			.blue {
				color: #4d44ff;
			}
		}

		.desc {
			width: 40%;

			h1 {
				display: flex;
				justify-content: flex-end;
				margin-top: 0;
				margin-bottom: 40px;
			}

			p {
				font-weight: lighter;
				line-height: 1.5;
				text-align: justify;
			}
		}
	}

	@media screen and (min-width: 1164px) {
		.content {
			.stats {
				.blue {
					font-size: 60px;
				}
			}

			.desc {
				h1 {
					font-size: 60px;
				}

				p {
					font-size: 25px;
				}
			}
		}
	}

	@media screen and (max-width: 1614px) {
		.content {
			h1 {
				font-size: 30px;
			}

			.desc {
				p {
					font-size: 16px;
				}
			}
		}
	}

	@media screen and (min-width: 1023px) {
		.content .stats span {
			font-size: 40px;
		}

		.content .stats .blue span {
			font-size: 10px;
		}
	}

	@media screen and (max-width: 1022px) {
		.content .stats span {
			font-size: 30px;
		}
	}

	@media screen and (max-width: 720px) {
		.content {
			flex-direction: column-reverse;

			.stats {
				width: 100%;
			}

			.desc {
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 627px) {
		.content .stats span {
			font-size: 25px;
		}
	}
}
