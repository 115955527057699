.portfolio {
	position: relative;
	width: 90vw;
	margin: 200px auto 90px auto;

	h1 {
		font-size: 60px;
		font-weight: bold;
	}

	.types {
		font-size: 25px;
		font-weight: bold;
		display: flex;
		margin: 0 auto;
		color: #949494;
		gap: 30px;

		span {
			&:nth-child(odd) {
				cursor: pointer;
				transition: all 0.5s ease-in-out;

				&:hover {
					color: #4d44ff;
				}
			}
		}

		.active {
			color: #4d44ff;
		}
	}

	.slider {
		min-height: 400px;
		margin: 150px 0 30px 0;

		.slide {
			font-weight: bold;
			display: flex;
			align-items: center;
			justify-content: center;

			.img {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.info {
				.index {
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
					width: 100%;
					margin-bottom: 0;
					text-align: right;

					.title {
						display: flex;
						justify-content: flex-end;
						margin-bottom: 0;
						transition: all 0.5s ease-in-out;
						text-align: right;
						text-decoration: none;
						color: black;

						&:hover {
							color: #4d44ff;
						}
					}

					.titleIndex {
						font-weight: bold;
					}
				}

				.type {
					font-weight: bold;
					display: flex;
					justify-content: flex-end;
					margin-top: 0;
					color: #4d4d4d;
				}

				.tools {
					display: flex;
					gap: 10px;
				}

				.desc {
					font-weight: lighter;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 20px auto 0 auto;
					padding-right: 40px;
				}
			}
		}
	}

	.options {
		font-weight: bold;
		display: flex;
		justify-content: center;
		color: #949494;
		gap: 15px;

		.swiper-pagination {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;

			.swiper-pagination-bullet {
				width: 10px;
				height: 10px;
				opacity: 1;
				border-radius: 50%;
				background: #dddddd;
			}

			.swiper-pagination-bullet-active {
				background: #4d44ff;
			}
		}

		span {
			font-size: 37px;
			cursor: pointer;
		}
	}

	@media screen and (min-width: 1410px) {
		.desc {
			font-size: 18px;
			line-height: 40px;
		}

		.tools {
			justify-content: flex-end;
		}

		.img {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50%;

			img {
				width: auto;
				min-width: 600px;
				max-width: 200%;
				height: auto;
				min-height: 400px;
				max-height: 200%;
				border-radius: 20px;
			}

			iframe {
				width: auto;
				min-width: 800px;
				max-width: 300%;
				height: auto;
				min-height: 600px;
				max-height: 300%;
				border-radius: 20px;
			}
		}

		.info {
			width: 50%;
			padding-left: 100px;

			.index {
				.titleIndex {
					font-size: 20px;
				}

				.title {
					font-size: 60px;
				}
			}

			h2.type {
				font-size: 24px;
			}

			.tools {
				font-size: 45px;
			}
		}
	}

	@media screen and (max-width: 1409px) {
		.tools {
			width: 100vw;
		}

		.img {
			img {
				width: auto;
				min-width: initial;
				max-width: 100%;
				height: auto;
				min-height: initial;
				max-height: 100%;
				border-radius: 20px;
			}

			iframe {
				width: auto;
				min-width: initial;
				max-width: 100%;
				height: auto;
				min-height: initial;
				max-height: 100%;
				border-radius: 20px;
			}
		}
	}

	@media screen and (max-width: 1199px) {
		.img {
			width: 100%;
		}

		.info {
			text-align: center;

			.index {
				.title {
					font-size: 50px;
				}
			}
		}
	}

	@media screen and (max-width: 1114px) {
		.img {
			width: auto;
			max-width: 100%;
			height: auto;
			max-height: 100%;
		}

		.types {
			font-size: 20px;
			gap: 20px;
		}
	}

	@media screen and (max-width: 870px) {
		.types {
			font-size: 15px;
			gap: 15px;
		}
	}

	@media screen and (max-width: 799px) {
		.slide {
			flex-direction: column;
			width: 100%;
			height: auto;

			.img {
				width: 100%;
			}

			.tools {
				font-size: 25px;
				width: 40px;
				height: 40px;
			}

			.info {
				width: 100%;

				p {
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: 678px) {
		.info {
			.index {
				.titleIndex {
					font-size: 20px;
				}

				.title {
					font-size: 30px;
				}
			}

			h2.type {
				font-size: 20px;
			}
		}
	}

	@media screen and (max-width: 649px) {
		.desc {
			line-height: 25px;
			font-size: 13px;
		}

		.types {
			span:nth-child(even) {
				display: none;
			}
		}
	}

	@media screen and (max-width: 669px) {
		.title {
			span {
				font-size: 10px;
			}
		}
	}

	@media screen and (max-width: 499px) {
		margin-top: 100px;

		h1 {
			font-size: 40px;
		}

		.types {
			flex-direction: column;
		}
	}
}
