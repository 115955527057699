.footer {
	display: grid;
	padding: 200px calc((100vw - 90vw) / 2);
	color: white;
	background: black;
	grid-template-columns: 60% 40%;

	.work {
		font-size: 100px;
		font-weight: bold;
		position: relative;
		display: flex;
		flex-direction: column;

		a {
			z-index: 1;
			color: white;
			text-decoration: none;
			transition: all 0.3s linear;

			&:hover {
				color: #4d44ff;
			}
		}

		.star {
			font-size: 100px;
			position: absolute;
			top: -50px;
			right: 180px;
		}
	}

	.links {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		justify-content: space-between;

		a {
			font-size: 20px;
			font-weight: lighter;
			position: relative;
			display: inline-block;
			transition: all 0.3s linear;
			text-decoration: none;
			color: white;

			&:after {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				content: '';
				transition: transform 0.25s ease-out;
				transform: scaleX(0);
				transform-origin: bottom right;
				background-color: snow;
			}

			&:hover:after {
				transform: scaleX(1);
				transform-origin: bottom left;
			}
		}
	}

	@media screen and (max-width: 1129px) {
		.work {
			font-size: 80px;

			.star {
				font-size: 80px;
				right: 150px;
			}
		}
	}

	@media screen and (max-width: 899px) {
		.work {
			font-size: 60px;

			.star {
				font-size: 60px;
				right: -50px;
			}
		}
	}

	@media screen and (max-width: 719px) {
		.work {
			font-size: 40px;
		}
	}

	@media screen and (max-width: 599px) {
		.work {
			font-size: 30px;
		}
	}

	@media screen and (max-width: 499px) {
		grid-template-columns: 1fr;

		.work {
			font-size: 30px;
			margin-bottom: 20px;
			text-align: center;

			.star {
				right: 50%;
				transform: translateX(50%);
			}
		}

		.links {
			align-items: center;
			flex-direction: column;
			justify-content: space-between;
		}
	}
}

.rights {
	display: flex;
	justify-content: space-between;
	padding: 50px calc((100vw - 90vw) / 2);
	color: white;
	border-top: white 2px solid;
	background: black;
}
