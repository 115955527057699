.navbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 90vw;
	margin: 0 auto;
	padding: 50px 0;

	.logo {
		display: flex;
		align-items: center;
		min-width: 40%;
		height: 80px;

		img {
			width: 60px;
			margin-right: 10px;
		}

		span {
			font-size: 20px;
			font-weight: 900;
			text-transform: uppercase;
		}
	}

	.links {
		display: flex;
		justify-content: space-between;
		width: 60%;

		.link {
			font-size: 14px;
			font-weight: 600;
			position: relative;
			display: inline-block;
			transition: all 0.3s linear;
			text-decoration: none;
			color: black;

			&:after {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				content: '';
				transition: transform 0.25s ease-out;
				transform: scaleX(0);
				transform-origin: bottom right;
				background-color: black;
			}

			&:hover:after {
				transform: scaleX(1);
				transform-origin: bottom left;
			}
		}
	}

	.burger-menu {
		z-index: 6;
		display: none;
		align-items: flex-end;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		div {
			margin: 3px 0;
			cursor: pointer;
		}
	}

	.burger-bar {
		width: 64px;
		height: 8px;
		background-color: black;
	}

	.burger-bar.clicked {
		background-color: white;
	}

	.burger-bar.clicked:nth-child(2) {
		transition: ease-out 0.5s;
		transform: rotate(135deg);
	}

	.burger-bar.clicked:nth-child(1) {
		transform: rotate(45deg) translate(8px, 8px);
		transition: ease-out 0.5s;
	}

	.burger-bar.clicked:nth-child(3) {
		transform: rotate(135deg) translate(-0.5em, 1em);
		transition: ease-out 0.5s;
	}

	.burger-bar.unclicked {
		transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
		transform: rotate(0) translate(0);
	}

	.buttons {
		display: none;
	}

	@media screen and (max-width: 999px) {
		.burger-menu {
			display: flex;
		}

		.links {
			position: fixed;
			z-index: 5;
			top: 0;
			right: 0;
			overflow-x: hidden;
			align-items: center;
			flex-direction: column;
			justify-content: flex-start;
			width: 85vw;
			height: 100vh;
			padding-top: 20vh;
			background: #151515;
			gap: 30px;

			.link {
				font-size: 20px;
				width: 80%;
				max-width: 70%;
				color: white;

				.line {
					margin-top: 20px;
					width: 100%;
					height: 2px;
					background: white;
				}
			}

			.buttons {
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				width: 70%;
				gap: 30px;

				a {
					font-size: 15px;
					font-weight: bold;
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 150px;
					height: 30px;
					padding: 10px 20px;
					text-decoration: none;
					color: white;
					background: #4d44ff;

					img {
						filter: invert(100%);
					}
				}
			}
		}
	}
}
