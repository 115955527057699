.capabilities {
	position: relative;
	width: 90vw;
	margin: 250px auto 20px auto;
	padding: 50px 0;

	img {
		position: absolute;
		top: -7px;
		left: 50%;
		width: 118px;
		transform: translateX(-50%);
	}

	h1 {
		position: relative;
		font-size: 65px;
		display: flex;
		justify-content: flex-end;
		margin-top: 0;
		margin-bottom: 40px;

		div {
			position: absolute;
			bottom: 0;
			right: 0;
			font-size: 65px;
			color: #f7f7f7;
			opacity: 0.1;
		}
	}

	.capabilitiesList {
		padding: 0 calc(100vw - 90vw);
	}

	@media screen and (max-width: 1799px) {
		img {
			display: none;
		}
	}

	@media screen and (max-width: 839px) {
		margin-top: 100px;

		h1 {
			font-size: 30px;
		}
	}
}
