.hero {
	position: relative;
	width: 100vw;
	min-height: 80vh;

	.rectangle {
		position: absolute;
		z-index: -1;
		top: -30vh;
		right: 6vw;
		width: 34vw;
		max-height: 70vh;
	}

	.star {
		position: absolute;
		z-index: -1;
		top: 28vh;
		right: 8vw;
	}

	.content {
		width: 90vw;
		margin: 7vh auto 0 auto;

		.inscription {
			font-size: 145px;
			font-weight: bold;
			position: relative;
			display: flex;
			flex-direction: column;

			.since {
				font-size: 18px;
				font-weight: lighter;
				height: 0;
				padding-left: 10px;
			}

			.subtitle {
				display: flex;

				.small {
					font-weight: 300;
				}
			}
		}

		.buttons {
			display: flex;
			width: 100%;
			margin-top: 20px;
			gap: 36px;

			a,
			a::after {
				transition: all 0.3s;
			}

			a {
				font-weight: bold;
				position: relative;
				display: flex;
				overflow-x: hidden;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				text-decoration: none;
				text-transform: uppercase;
				color: black;
				background: none;
				gap: 10px;
			}

			a::before,
			a::after {
				position: absolute;
				z-index: -1;
				content: '';
			}

			img {
				z-index: 2;
				width: 25px;
				height: 25px;
			}

			span {
				z-index: 2;
			}

			.portfolioButton {
				background: #4d44ff;

				&::after {
					z-index: 1;
					top: 0;
					left: -35%;
					width: 0;
					height: 100%;
					transition-duration: 0.6s;
					transform: skew(50deg);
					transform-origin: top left;
					background-color: #dddddd;
				}

				&:hover:after {
					width: 135%;
					height: 100%;
				}
			}

			.talkButton {
				background: #dddddd;

				&::after {
					z-index: 1;
					top: 0;
					left: -35%;
					width: 0;
					height: 100%;
					transition-duration: 0.6s;
					transform: skew(50deg);
					transform-origin: top left;
					background-color: #4d44ff;
				}

				&:hover:after {
					width: 135%;
					height: 100%;
				}
			}
		}
	}

	@media screen and (min-width: 1560px) {
		.title {
			height: 160px;
		}

		.small {
			font-size: 32px;
		}

		.buttons {
			align-items: flex-start;

			a {
				font-size: 27px;
				width: 298px;
				height: 60px;
			}
		}

		.subtitle {
			align-items: center;
			gap: 50px;
		}
	}

	@media screen and (max-width: 1559px) {
		.buttons a {
			font-size: 27px;
			width: 298px;
			height: 60px;
		}

		.since {
			font-size: 18px;
			font-weight: lighter;
			height: 0;
			padding-left: 10px;
		}

		.title {
			font-size: 120px;
			height: 140px;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		.subtitle {
			font-size: 120px;

			.small {
				font-size: 28px;
				display: flex;
				align-items: center;
				padding-left: 10px;
			}
		}
	}

	@media screen and (max-width: 1388px) {
		.title {
			font-size: 100px;
			height: 100px;
		}

		.subtitle {
			font-size: 100px;

			.small {
				font-size: 20px;
			}
		}
	}

	@media screen and (max-width: 1019px) {
		.line {
			display: none;
		}

		.title {
			font-size: 90px;
			height: 90px;
		}

		.subtitle {
			font-size: 90px;

			.small {
				font-size: 17px;
			}
		}
	}

	@media screen and (max-width: 915px) {
		.title {
			font-size: 80px;
			height: 80px;
		}

		.subtitle {
			font-size: 80px;

			.small {
				font-size: 15px;
			}
		}
	}

	@media screen and (max-width: 808px) {
		.title {
			font-size: 70px;
			height: 70px;
			margin-top: 5px;
		}

		.subtitle {
			font-size: 70px;

			.small {
				font-size: 13px;
			}
		}

		.subtitle {
			align-items: center;
		}
	}

	@media screen and (max-width: 720px) {
		.since {
			display: none;
		}

		.inscription {
			flex-direction: column;
		}

		.buttons {
			align-items: flex-start;
			flex-direction: column;
			margin-top: 30px;
		}

		.subtitle {
			align-items: flex-start;
			flex-direction: column;

			.small {
				font-size: 20px;
			}
		}
	}

	@media screen and (max-width: 713px) {
		.title {
			font-size: 60px;
			height: 60px;
		}

		.subtitle {
			font-size: 60px;
		}
	}

	@media screen and (max-width: 651px) {
		.title {
			font-size: 50px;
			height: 50px;
			margin-top: 10px;
		}

		.subtitle {
			font-size: 50px;
			gap: 20px;
		}
	}

	@media screen and (max-width: 595px) {
		.subtitle {
			flex-direction: column;
			gap: 5px;
		}

		.subtitle {
			font-size: 40px;
		}

		.buttons a {
			font-size: 20px;
			width: 250px;
			height: 60px;
		}
	}

	@media screen and (max-width: 553px) {
		.star {
			display: none;
		}
	}

	@media screen and (max-width: 505px) {
		.title {
			font-size: 40px;
			height: 40px;
		}

		.subtitle {
			font-size: 40px;
		}

		.buttons a {
			font-size: 20px;
			width: 250px;
			height: 60px;
		}
	}

	@media screen and (max-width: 459px) {
		.buttons a {
			font-size: 18px;
			width: 200px;
			height: 60px;
		}
	}

	@media screen and (max-width: 404px) {
		.title {
			font-size: 30px;
			margin-top: 20px;
		}

		.subtitle {
			font-size: 30px;
		}
	}
}
