@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200;300;400;500;600;700;800;900&display=swap');

* {
	max-width: 100vw;
	scroll-behavior: smooth;
}

body::-webkit-scrollbar {
	display: none;
}

body {
	font-family: 'Unbounded', sans-serif;
	overflow-x: hidden;
	max-width: 100vw;
	margin: 0;
}
